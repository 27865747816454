import React from 'react'
import lightning from './images/lightning.png'

function Lightning(props) {
  return (
    <div className="iconSection">
      <img src={lightning} className="lightning" alt={"There is lightning, do not go outside."}/>
      <h2 class="lightalert">LIGHTNING ALERT</h2>
      <img src={lightning} className="lightning" alt={"There is lightning, do not go outside."}/>
    </div>
  )
}

export default Lightning;
