import React from 'react';
import menuIcon from './images/menu_icon.png'


function Header(props) {
  return <section className="header">
  <div className="menuContainer">
    <img className="menu" src={menuIcon} alt={"Menu Icon"} width={"50px"} onClick={props.menuHandler} draggable={false}/>
  </div>
    <h1 className="title">IPOS CHECKER</h1>
  </section>
}

export default Header;
