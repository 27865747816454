import React from 'react'
// import umbrella from './images/umbrella.png'
import umbrellaDown from './images/umbrelladown.png'


function Body(props) {
  return (
    <div className="iconSection">
      <div className="infoSection">
        <h4 className="peabodyDescription">{props.outsideArray[props.condition - 1]}</h4>
        <img src={props.peabodyArray[props.condition - 1]} className="peabody" alt="Shows Peabody inside or outside"/>
        <h4 className="rotationDescription">Max Time Between Rotations</h4>
        <img src={props.rotationArray[props.condition - 1]} alt="Tells the rotation condition" className="rotation"/>
      </div>
      <div className="notesSection">
        <img src={umbrellaDown} alt="Umbrella is Down"/>
        <h3 className="notes">Additional Notes: {props.notesArray[props.condition - 1]}</h3>
      </div>

    </div>
  )
}



export default Body
